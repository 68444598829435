import api from '../api'

class ProductService {

	static getProducts(page) {
		return api({
			method: 'get',
			params: { page: page },
			url: 'products'
		})
	}

	static getCategories() {
		return api({
			url: 'categories-products'
		})
  }

  static getAttributes() {
		return api({
			url: 'attributes'
		})
  }

  static storeAttribute(data) {
		return api({
      method: 'post',
      data,
			url: 'attributes'
		})
  }

  static updateAttribute(data) {
		return api({
      method: 'put',
      data,
			url: 'attributes'
		})
  }

  static deleteAttribute(data) {
		return api({
      method: 'delete',
      data,
			url: 'attributes'
		})
	}

	static storeProduct(data) {
		return api({
			method: 'post',
			data: data,
			url: 'products'
		})
	}

	static updateProduct(data) {
		return api({
			method: 'post',
			data: data,
			url: 'products'
		})
  }

	static storeCategory(data) {
		return api({
			method: 'post',
			data: data,
			url: 'categories-products'
		})
  }

  static updateCategory(data) {
		return api({
			method: 'patch',
			data: data,
			url: 'categories-products'
		})
	}

	static showHideProduct(data) {
		return api({
			method: 'patch',
			data: data,
			url: 'show-hide-product'
		})
	}

	static deleteProduct(data) {
		return api({
			method: 'delete',
			data: data,
			url: 'products'
		})
	}

	static deleteImageProduct(data) {
		return api({
			method: 'delete',
			data: data,
			url: 'image-product'
		})
	}
}

export default ProductService
