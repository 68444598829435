var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-between"},[_c('vs-breadcrumb',{attrs:{"items":[
          {
            title: 'Admin Tag Store',
            url: '/'
          },
          {
            title: 'Categorias de productos',
            active: true
          }
        ]}}),_c('div',[_c('vs-button',{attrs:{"icon":"add","type":"gradient"},on:{"click":function($event){_vm.open = true}}},[_vm._v("Crear nueva")])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-card',[_c('vs-table',{attrs:{"stripe":"","data":_vm.categories},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"text-left"},[_c('vs-td',{attrs:{"data":data[indextr].name}},[_vm._v(" "+_vm._s(data[indextr].name)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].products}},[_vm._v(" "+_vm._s(data[indextr].products.length)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].id}},[_c('div',{staticClass:"d-flex"},[_c('vs-button',{staticClass:"mr-2",attrs:{"icon":"create","color":"primary","type":"gradient"},on:{"click":function($event){return _vm.editCategory(data[indextr])}}},[_vm._v("Editar")])],1)])],1)})}}])},[_c('template',{slot:"header"},[_c('h3',[_vm._v(" Lista de categorias ")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" Nombre ")]),_c('vs-th',[_vm._v(" Productos existentes ")]),_c('vs-th',[_vm._v(" Acciones ")])],1)],2)],1)],1)]),_c('vs-popup',{attrs:{"classContent":"popup-example","title":_vm.edit ? 'Editar categoria' : 'Registrar categoria',"active":_vm.open},on:{"update:active":function($event){_vm.open=$event}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":""}},[_vm._v("Nombre de la categoria")]),_c('vs-input',{staticClass:"inputx",attrs:{"placeholder":"Nombre de la categoria"},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-right"},[(_vm.edit)?_c('vs-button',{staticClass:"mt-5",attrs:{"icon":"save","disabled":_vm.loading,"color":"primary","type":"gradient"},on:{"click":_vm.updateCategory}},[_vm._v("Editar")]):_c('vs-button',{staticClass:"mt-5",attrs:{"icon":"save","disabled":_vm.loading,"color":"primary","type":"gradient"},on:{"click":_vm.storeCategory}},[_vm._v("Guardar")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }