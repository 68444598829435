<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Categorias de productos',
              active: true
            }
          ]"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nueva</vs-button>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table stripe :data="categories">
            <template slot="header">
              <h3>
                Lista de categorias
              </h3>
            </template>
            <template slot="thead">
              <vs-th>
                Nombre
              </vs-th>
              <vs-th>
                Productos existentes
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td :data="data[indextr].name">
                  {{data[indextr].name}}
                </vs-td>

                <vs-td :data="data[indextr].products">
                  {{data[indextr].products.length}}
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <div class="d-flex">
                    <vs-button icon="create" @click="editCategory(data[indextr])" class="mr-2" color="primary" type="gradient">Editar</vs-button>
                    <!--<vs-button color="danger" type="gradient">Eliminar</vs-button>-->
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-card>
      </div>
    </div>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar categoria' : 'Registrar categoria'" :active.sync="open">
      <div class="row">
        <div class="col">
          <label for="">Nombre de la categoria</label>
          <vs-input v-model="category.name" class="inputx" placeholder="Nombre de la categoria"/>
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          <vs-button icon="save" :disabled="loading" v-if="edit" class="mt-5" @click="updateCategory" color="primary" type="gradient">Editar</vs-button>
          <vs-button icon="save" :disabled="loading" v-else class="mt-5" @click="storeCategory" color="primary" type="gradient">Guardar</vs-button>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import productsService from '@/services/product.service'
export default {
  data () {
    return {
      loading: false,
      error: false,
      open: false,
      edit: false,

      category: {
        name: ''
      },
      categories: []
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.category = JSON.parse(JSON.stringify(this.$options.data().category))
      }
    }
  },
  methods: {
    async getCategories() {
      this.$vs.loading({
        type: 'point'
      })
      const { data:res } = await productsService.getCategories()
      if (res.success) {
        this.categories = res.data
      } else {
        this.error = true
      }
      this.$vs.loading.close()
    },
    async storeCategory() {
      this.$validator.validateAll().then(async result => {
        try {
          this.loading = true
          const { data:res } = await productsService.storeCategory(this.category)

          if (res.success) {
            this.open = false
            this.categories.push(res.data)
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }

        } catch(e) {
          console.log(e)
        }
        this.loading = false
      })
    },
    editCategory(item) {
      this.category = JSON.parse(JSON.stringify(item))
      this.open = true
      this.edit = true
    },
    async updateCategory() {
      try {
        this.loading = true
        const { data:res } = await productsService.updateCategory(this.category)

        if (res.success) {
          const index = this.categories.findIndex(i => i.id == res.data.id)
          this.categories[index] = res.data
          this.open = false
          this.categories = JSON.parse(JSON.stringify(this.categories))
          this.$vs.notify({
            title: res.message,
            text:'',
            color: 'success',
            position: 'top-right'
          })
        } else {
          console.log(res)
        }
      } catch(e) {
        console.log(e)
      }
      this.loading = false
    }
  },
  created() {
    this.getCategories()
  }
}
</script>
